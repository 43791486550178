import {useState} from 'react';
import {
    Calendar,
    Phone,
    Clock,
    Shield,
    Star,
    MessageCircle,
    ArrowRight,
    Wrench,
    CheckCircle,
    Award,
    AlertCircle,
    DollarSign,
    MapPin
} from 'lucide-react';

import lg from '../../Assets/images/lgft.webp'
import samsung from '../../Assets/images/samsungft.webp'
import whirlpool from '../../Assets/images/whirlpool.webp'
import electrolux from '../../Assets/images/electrolux.webp'
import mabewm from '../../Assets/images/mabe.webp'
import centrales from '../../Assets/images/centrales.webp'
import haceb from '../../Assets/images/haceb.webp'
import challenger from '../../Assets/images/challenger.webp'
import {Helmet} from "react-helmet-async";

export default function ModernWashingService() {
    const [selectedTestimonial, setSelectedTestimonial] = useState(0);

    const brands = [
        {
            name: "LG",
            type: "Carga frontal",
            image: lg,
            description: "Especialistas certificados en lavadoras LG de carga frontal y superior. Reparación de tarjetas electrónicas y sistemas avanzados.",
            features: ["Diagnóstico computarizado", "Repuestos originales", "Garantía extendida"]
        },
        {
            name: "Samsung",
            type: "Carga superior",
            image: samsung,
            description: "Servicio técnico autorizado para toda la línea Samsung. Expertos en tecnología EcoBubble y programas especiales.",
            features: ["Servicio premium", "Soporte 24/7", "Técnicos certificados"]
        },
        {
            name: "Whirlpool",
            type: "Carga frontal y superior",
            image: whirlpool,
            description: "Expertos en mantenimiento de lavadoras Whirlpool. Especialización en sistemas Xpert System y 6th Sense.",
            features: ["Mantenimiento preventivo", "Reparación integral", "Asesoría especializada"]
        },
        {
            name: "Electrolux",
            type: "Todas las referencias",
            image: electrolux,
            description: "Reparación de lavadoras Electrolux de todas las líneas. Especialistas en sistemas Perfect Care y UltraCare.",
            features: ["Diagnóstico avanzado", "Servicio express", "Garantía de fábrica"]
        },
        {
            name: "Mabe",
            type: "carga frontal",
            image: mabewm,
            description: "Servicio especializado para lavadoras Mabe con atención a sistemas Aquasaver y programas eco-friendly.",
            features: ["Atención personalizada", "Repuestos certificados", "Soporte técnico permanente"]
        },
        {
            name: "Centrales",
            type: "Todo tipo",
            image: centrales,
            description: "Mantenimiento experto de centros de lavado y secado. Soluciones integrales para sistemas duales.",
            features: ["Servicio industrial", "Mantenimiento programado", "Soporte empresarial"]
        },
        {
            name: "Haceb",
            type: "Línea completa",
            image: haceb,
            description: "Reparación profesional de toda la línea de lavadoras Haceb. Expertos en sistemas nacionales.",
            features: ["Servicio local", "Atención inmediata", "Garantía nacional"]
        },
        {
            name: "Challenger",
            type: "Todos los modelos",
            image: challenger,
            description: "Servicio técnico especializado para lavadoras Challenger. Mantenimiento integral y actualización de sistemas.",
            features: ["Diagnóstico gratuito", "Repuestos originales", "Garantía extendida"]
        }
    ];

    const testimonials = [
        {
            name: "Carolina Ramírez",
            location: "Chapinero",
            rating: 5,
            comment: "Excelente servicio, muy profesionales y puntuales. Repararon mi lavadora LG el mismo día y a un precio muy justo.",
            image: "/testimonial1.webp",
            date: "Hace 2 semanas"
        },
        {
            name: "Juan Diego Martínez",
            location: "Usaquén",
            rating: 5,
            comment: "Mi lavadora Samsung dejó de funcionar y pensé que tendría que comprar una nueva. El técnico la reparó en menos de 2 horas. ¡Increíble servicio!",
            image: "/testimonial2.webp",
            date: "Hace 1 mes"
        },
        {
            name: "María Fernanda López",
            location: "Suba",
            rating: 5,
            comment: "Súper recomendados. Atención rápida, excelente servicio al cliente y garantía real. Mi lavadora quedó como nueva.",
            image: "/testimonial3.webp",
            date: "Hace 3 días"
        }
    ];

    const commonProblems = [
        {
            title: "No Centrifuga",
            description: "Solución profesional para problemas de centrifugado y balance",
            solution: "Diagnóstico y reparación del sistema de transmisión",
            price: "Desde $80.000"
        },
        {
            title: "No Desagua",
            description: "Reparación de sistemas de desagüe y bombas de agua",
            solution: "Limpieza y reparación de bomba de drenaje",
            price: "Desde $70.000"
        },
        {
            title: "No Enciende",
            description: "Diagnóstico y reparación de sistemas eléctricos",
            solution: "Revisión de tarjeta electrónica y componentes",
            price: "Desde $90.000"
        },
        {
            title: "Ruidos Extraños",
            description: "Identificación y corrección de ruidos anormales",
            solution: "Ajuste de rodamientos y tambor",
            price: "Desde $85.000"
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">

            <Helmet>
                <title>Reparación y Mantenimiento de Lavadoras en Bogotá</title>
                <meta
                    name="description"
                    content="Ofrecemos mantenimiento, reparación y servicio técnico especializado para lavadoras en Bogotá. Garantizamos calidad, precios justos y atención rápida. ¡Contáctanos ahora!"
                />
                <meta
                    name="keywords"
                    content="mantenimiento de lavadoras Bogotá, reparación de lavadoras, servicio técnico lavadoras Bogotá, arreglo de lavadoras, técnicos de lavadoras en Bogotá"
                />
                <meta name="robots" content="index, follow"/>
                <link rel="canonical" href="https://mantenimientolavadorasariza.com"/>
                <meta
                    property="og:title"
                    content="Mantenimiento de Lavadoras en Bogotá | Servicio Técnico Especializado"
                />
                <meta
                    property="og:description"
                    content="Expertos en mantenimiento y reparación de lavadoras en Bogotá. Servicio garantizado con técnicos especializados. Solicita tu cita hoy mismo."
                />
                <meta
                    property="og:url"
                    content="https://mantenimientolavadorasariza.com"
                />
                <meta
                    property="og:type"
                    content="website"
                />
                <meta
                    property="og:image"
                    content="https://mantenimientolavadorasariza.com/static/media/logo.36775a3469eb391807f7.webp"
                />
            </Helmet>

            <div className="relative bg-gradient-to-r from-blue-900 to-blue-700 text-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                    <div className="text-center lg:text-left lg:w-2/3">
                        <h1 className="text-4xl lg:text-6xl font-bold leading-tight my-16">
                            Expertos en Reparación y Mantenimiento de Lavadoras a Domicilio
                        </h1>
                        <p className="text-xl lg:text-2xl mb-8 text-blue-100">
                            Servicio técnico profesional con respuesta en 2 horas. ¡Garantía por escrito en todas las
                            reparaciones!
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                            <a href="https://wa.link/wo9nty">
                                <button
                                    className="bg-green-500 hover:bg-green-600 text-white px-8 py-4 rounded-lg flex items-center justify-center gap-2 transition-all transform hover:scale-105">
                                    <MessageCircle className="w-5 h-5"/>
                                    WhatsApp Directo
                                    <span className="text-sm bg-green-600 px-2 py-1 rounded">10% OFF</span>
                                </button>
                            </a>
                            <a href="tel:+573173693893">
                                <button
                                    className="bg-blue-100 hover:bg-blue-200 text-blue-900 px-8 py-4 rounded-lg flex items-center justify-center gap-2 transition-all">
                                    <Phone className="w-5 h-5"/>
                                    Llamar Ahora
                                </button>
                            </a>
                        </div>
                        <div className="mt-8 text-blue-100 flex items-center gap-4 justify-center lg:justify-start">
                            <CheckCircle className="w-5 h-5"/>
                            <span>Respuesta en 2 horas garantizada</span>
                        </div>
                    </div>
                </div>

                {/* Enhanced Stats Card */}
                <div
                    className="absolute bottom-10 right-0 lg:right-20 transform translate-y-1/2 bg-white rounded-xl shadow-xl p-6 mx-4 lg:mx-0">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
                        <div className="text-center">
                            <p className="text-3xl font-bold text-blue-900">5000+</p>
                            <p className="text-sm text-gray-600">Lavadoras Reparadas</p>
                        </div>
                        <div className="text-center">
                            <p className="text-3xl font-bold text-blue-900">15+</p>
                            <p className="text-sm text-gray-600">Años de Experiencia</p>
                        </div>
                        <div className="text-center">
                            <p className="text-3xl font-bold text-blue-900">4.8/5</p>
                            <p className="text-sm text-gray-600">Calificación Clientes</p>
                        </div>
                        <div className="text-center">
                            <p className="text-3xl font-bold text-blue-900">2000+</p>
                            <p className="text-sm text-gray-600">Recomendaciones</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Emergency Service Banner */}
            <div className="bg-yellow-50 border-t border-b border-yellow-100">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <div className="flex items-center justify-center gap-4 text-yellow-800">
                        <AlertCircle className="w-6 h-6"/>
                        <p className="text-lg font-medium">
                            ¿Emergencia con tu lavadora? ¡Servicio express disponible 24/7!
                            <span className="ml-2 text-yellow-600 underline cursor-pointer">
                                Contactar ahora
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            {/* Enhanced Brands Section */}
            <div className="bg-gradient-to-b from-blue-50 to-white py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-4xl font-bold text-center mb-6 text-blue-900">
                        Marcas que Reparamos
                    </h2>
                    <p className="text-xl text-center mb-12 text-gray-600">
                        Servicio técnico y Mantenimiento especializado para todas las marcas de lavadoras principales del mercado
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {brands.map((brand, index) => (
                            <div key={index}
                                 className="group relative bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
                                <div className="aspect-w-16 aspect-h-9 bg-gradient-to-br from-blue-50 to-blue-100 p-8">
                                    <img
                                        src={brand.image}
                                        alt={`Lavadora ${brand.name}`}
                                        className="object-contain w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                                        loading="lazy"
                                    />
                                </div>
                                <div className="p-6">
                                    <h3 className="text-2xl font-bold text-blue-900 mb-2">
                                        {brand.name}
                                    </h3>
                                    <p className="text-blue-600 font-medium mb-2">
                                        {brand.type}
                                    </p>
                                    <p className="text-gray-600 mb-4">
                                        {brand.description}
                                    </p>
                                    <ul className="space-y-2">
                                        {brand.features.map((feature, idx) => (
                                            <li key={idx} className="flex items-center gap-2 text-sm text-gray-600">
                                                <CheckCircle className="w-4 h-4 text-green-500"/>
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div
                                    className="absolute top-4 right-4 bg-blue-600 text-white py-1 px-3 rounded-full text-sm">
                                    Servicio Oficial
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-gradient-to-b from-blue-50 via-white to-blue-50 py-24">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold text-blue-900 mb-4">
                            Servicio Técnico en Reparación y Mantenimiento de Lavadoras
                        </h2>
                        <p className="text-xl text-blue-600">Tu servicio técnico confiable en Bogotá</p>
                    </div>

                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="group">
                            <div
                                className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 border border-blue-100 h-full">
                                <div className="flex items-center gap-4 mb-6">
                                    <div className="bg-blue-100 p-3 rounded-xl">
                                        <Wrench className="w-6 h-6 text-blue-600"/>
                                    </div>
                                    <h3 className="text-2xl font-semibold text-blue-800">
                                        Mantenimiento Profesional
                                    </h3>
                                </div>
                                <div className="space-y-4 text-gray-600">
                                    <p>
                                        Brindamos <span className="text-blue-700 font-medium">servicio técnico especializado</span> para
                                        todas las marcas de lavadoras en Bogotá. Nuestro equipo realiza:
                                    </p>
                                    <ul className="space-y-2 ml-6">
                                        <li className="list-disc">Diagnóstico computarizado completo</li>
                                        <li className="list-disc">Reparación de sistemas electrónicos</li>
                                        <li className="list-disc">Mantenimiento preventivo y correctivo</li>
                                        <li className="list-disc">Solución de problemas de centrifugado</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="group">
                            <div
                                className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 border border-blue-100 h-full">
                                <div className="flex items-center gap-4 mb-6">
                                    <div className="bg-blue-100 p-3 rounded-xl">
                                        <Shield className="w-6 h-6 text-blue-600"/>
                                    </div>
                                    <h3 className="text-2xl font-semibold text-blue-800">
                                        Servicio Garantizado
                                    </h3>
                                </div>
                                <div className="space-y-4 text-gray-600">
                                    <p>
                                        Como <span className="text-blue-700 font-medium">expertos en reparación de lavadoras</span>,
                                        ofrecemos:
                                    </p>
                                    <ul className="space-y-2 ml-6">
                                        <li className="list-disc">Atención inmediata a domicilio</li>
                                        <li className="list-disc">Repuestos originales certificados</li>
                                        <li className="list-disc">Técnicos especializados por marca</li>
                                        <li className="list-disc">Garantía por escrito en reparaciones</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-16 bg-blue-900 rounded-2xl p-8 text-white">
                        <div className="grid md:grid-cols-3 gap-8">
                            <div className="text-center">
                                <Clock className="w-12 h-12 mx-auto mb-4 text-blue-300"/>
                                <h4 className="text-xl font-semibold mb-2">Respuesta Rápida</h4>
                                <p className="text-blue-200">Atención en menos de 2 horas en toda Bogotá</p>
                            </div>
                            <div className="text-center">
                                <Shield className="w-12 h-12 mx-auto mb-4 text-blue-300"/>
                                <h4 className="text-xl font-semibold mb-2">100% Garantizado</h4>
                                <p className="text-blue-200">Hasta 6 meses de garantía en reparaciones</p>
                            </div>
                            <div className="text-center">
                                <Wrench className="w-12 h-12 mx-auto mb-4 text-blue-300"/>
                                <h4 className="text-xl font-semibold mb-2">Servicio Completo</h4>
                                <p className="text-blue-200">Mantenimiento integral para todas las marcas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* Enhanced Features Section */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                <h2 className="text-4xl font-bold text-center mb-16 text-blue-900">
                    ¿Por qué somos tu mejor opción para el servicio tecnico?
                </h2>
                <div className="grid md:grid-cols-3 gap-8">
                    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                        <div className="bg-blue-100 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                            <Clock className="w-6 h-6 text-blue-600"/>
                        </div>
                        <h3 className="text-xl font-semibold mb-2">Servicio Express</h3>
                        <p className="text-gray-600">Atención en menos de 2 horas en casos urgentes. Solución el mismo
                            día garantizada.</p>
                        <ul className="mt-4 space-y-2">
                            <li className="flex items-center gap-2 text-sm text-gray-600">
                                <CheckCircle className="w-4 h-4 text-green-500"/>
                                Disponibilidad 24/7
                            </li>
                            <li className="flex items-center gap-2 text-sm text-gray-600">
                                <CheckCircle className="w-4 h-4 text-green-500"/>
                                Técnicos cercanos a tu zona
                            </li>
                        </ul>
                    </div>

                    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                        <div className="bg-blue-100 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                            <Shield className="w-6 h-6 text-blue-600"/>
                        </div>
                        <h3 className="text-xl font-semibold mb-2">Garantía Total</h3>
                        <p className="text-gray-600">Respaldamos nuestro servicio tecnico con hasta 6 meses de garantía por
                            escrito.</p>
                        <ul className="mt-4 space-y-2">
                            <li className="flex items-center gap-2 text-sm text-gray-600">
                                <CheckCircle className="w-4 h-4 text-green-500"/>
                                Repuestos originales
                            </li>
                            <li className="flex items-center gap-2 text-sm text-gray-600">
                                <CheckCircle className="w-4 h-4 text-green-500"/>
                                Servicio tecnico post-reparación
                            </li>
                        </ul>
                    </div>

                    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                        <div className="bg-blue-100 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                            <Star className="w-6 h-6 text-blue-600"/>
                        </div>
                        <h3 className="text-xl font-semibold mb-2">Técnicos Certificados</h3>
                        <p className="text-gray-600">Equipo profesional con certificaciones internacionales y años de
                            experiencia.</p>
                        <ul className="mt-4 space-y-2">
                            <li className="flex items-center gap-2 text-sm text-gray-600">
                                <CheckCircle className="w-4 h-4 text-green-500"/>
                                Capacitación continua
                            </li>
                            <li className="flex items-center gap-2 text-sm text-gray-600">
                                <CheckCircle className="w-4 h-4 text-green-500"/>
                                Herramientas especializadas
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Common Problems Section */}
            <div className="bg-blue-50 py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-4xl font-bold text-center mb-16 text-blue-900">
                        Soluciones Comunes
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {commonProblems.map((problem, index) => (
                            <div key={index}
                                 className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all">
                                <h3 className="text-xl font-bold text-blue-900 mb-3">{problem.title}</h3>
                                <p className="text-gray-600 mb-4">{problem.description}</p>
                                <div className="flex items-center gap-2 text-blue-600 mb-3">
                                    <Wrench className="w-4 h-4"/>
                                    <span className="text-sm">{problem.solution}</span>
                                </div>
                                <div className="flex items-center gap-2 text-green-600 font-semibold">
                                    <DollarSign className="w-4 h-4"/>
                                    <span>{problem.price}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            {/* Testimonials Section */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                <h2 className="text-4xl font-bold text-center mb-16 text-blue-900">
                    Lo que dicen nuestros clientes sobre el mantenimiento
                </h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                            <div className="flex items-center gap-4 mb-4">
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                    className="w-16 h-16 rounded-full object-cover"
                                />
                                <div>
                                    <h3 className="font-semibold">{testimonial.name}</h3>
                                    <p className="text-gray-600 text-sm">{testimonial.location}</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-1 mb-4">
                                {[...Array(testimonial.rating)].map((_, i) => (
                                    <Star key={i} className="w-5 h-5 text-yellow-400 fill-current"/>
                                ))}
                            </div>
                            <p className="text-gray-600 mb-4">{testimonial.comment}</p>
                            <p className="text-sm text-gray-500">{testimonial.date}</p>
                        </div>
                    ))}
                </div>
                <div className="text-center mt-12">
                    <button className="text-blue-600 font-medium hover:text-blue-700">
                        Ver más reseñas →
                    </button>
                </div>
            </div>

            <div className="bg-gradient-to-b from-white to-blue-50 py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-3xl mx-auto text-center mb-16">
                        <h2 className="text-4xl font-bold text-blue-900 mb-4">
                            Servicio Técnico de Lavadoras en Bogotá
                        </h2>
                        <p className="text-lg text-blue-700">
                            Soluciones profesionales para todas las marcas
                        </p>
                    </div>

                    <div className="grid gap-12">
                        <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300">
                            <div className="prose max-w-none">
                                <h3 className="text-2xl font-semibold text-blue-800 mb-6">
                                    Expertos en Reparación y Mantenimiento de Lavadoras
                                </h3>
                                <div className="space-y-6 text-gray-700">
                                    <p>
                                        Nos especializamos en la <span className="text-blue-700 font-medium">reparación profesional de lavadoras</span> en
                                        Bogotá,
                                        ofreciendo soluciones integrales para todas las marcas y modelos. Nuestro
                                        servicio técnico
                                        especializado cuenta con más de 15 años de experiencia en el diagnóstico y
                                        reparación de
                                        problemas comunes y complejos.
                                    </p>

                                    <p>
                                        Como líderes en <span className="text-blue-700 font-medium">mantenimiento de lavadoras en Bogotá</span>,
                                        brindamos atención personalizada y soluciones efectivas para:
                                    </p>

                                    <div className="grid md:grid-cols-2 gap-6 my-8">
                                        <ul className="space-y-3 text-gray-600">
                                            <li className="flex items-center">
                                                <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                                Fallas en el sistema de centrifugado
                                            </li>
                                            <li className="flex items-center">
                                                <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                                Problemas de desagüe y filtración
                                            </li>
                                            <li className="flex items-center">
                                                <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                                Reparación de tarjetas electrónicas
                                            </li>
                                        </ul>
                                        <ul className="space-y-3 text-gray-600">
                                            <li className="flex items-center">
                                                <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                                Cambio de rodamientos y sellos
                                            </li>
                                            <li className="flex items-center">
                                                <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                                Mantenimiento preventivo
                                            </li>
                                            <li className="flex items-center">
                                                <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                                Reemplazo de repuestos originales
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="bg-blue-50 p-6 rounded-xl">
                                        <h4 className="text-xl font-semibold text-blue-800 mb-4">
                                            Servicio Técnico Certificado
                                        </h4>
                                        <p className="text-gray-700">
                                            Nuestro equipo de <span className="text-blue-700 font-medium">técnicos especializados en lavadoras</span>
                                            está certificado para trabajar con todas las marcas principales del mercado.
                                            Ofrecemos
                                            diagnóstico gratuito, presupuesto sin compromiso y garantía por escrito en
                                            todas
                                            nuestras reparaciones.
                                        </p>
                                    </div>

                                    <div className="mt-8">
                                        <h4 className="text-xl font-semibold text-blue-800 mb-4">
                                            Cobertura en Toda Bogotá
                                        </h4>
                                        <p className="text-gray-700">
                                            Brindamos <span className="text-blue-700 font-medium">servicio técnico de lavadoras a domicilio</span> en
                                            todas las localidades de Bogotá, con atención de emergencia 24/7 y tiempos
                                            de respuesta
                                            menores a 2 horas. Nuestro compromiso es ofrecer soluciones rápidas y
                                            efectivas para
                                            mantener su lavadora en óptimo funcionamiento.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Service Areas Section */}
            <div className="bg-blue-50 py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-4xl font-bold text-center mb-12 text-blue-900">
                        Zonas de Servicio
                    </h2>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                        {[
                            "Usaquén", "Chapinero", "Santa Fe", "San Cristóbal",
                            "Usme", "Tunjuelito", "Bosa", "Kennedy",
                            "Fontibón", "Engativá", "Suba", "Barrios Unidos",
                            "Teusaquillo", "Los Mártires", "Antonio Nariño", "Puente Aranda"
                        ].map((zone, index) => (
                            <div key={index} className="flex items-center gap-2">
                                <MapPin className="w-4 h-4 text-blue-600"/>
                                <span>{zone}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Enhanced Process Section */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                <h2 className="text-4xl font-bold text-center mb-16 text-blue-900">
                    Proceso Simple y Efectivo
                </h2>
                <div className="grid md:grid-cols-4 gap-8">
                    {[
                        {
                            step: "1",
                            title: "Contacto Inmediato",
                            description: "Agenda tu servicio por WhatsApp o llamada con respuesta en minutos"
                        },
                        {
                            step: "2",
                            title: "Diagnóstico Profesional",
                            description: "Revisión completa de tu lavadora por expertos certificados"
                        },
                        {
                            step: "3",
                            title: "Cotización Clara",
                            description: "Presupuesto detallado y transparente sin costos ocultos"
                        },
                        {
                            step: "4",
                            title: "Reparación Garantizada",
                            description: "Servicio profesional con garantía por escrito"
                        }
                    ].map((item, index) => (
                        <div key={index} className="relative">
                            <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
                                <span className="text-blue-600 font-bold text-xl">{item.step}</span>
                            </div>
                            <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                            <p className="text-gray-600">{item.description}</p>
                            {index < 3 && (
                                <ArrowRight
                                    className="hidden md:block absolute top-6 right-0 w-6 h-6 text-blue-300 transform translate-x-1/2"/>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Enhanced CTA Section */}
            <div className="bg-gradient-to-r from-blue-900 to-blue-700 text-white py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-4xl font-bold mb-6">
                        ¿Problemas con tu lavadora?
                    </h2>
                    <p className="text-xl mb-8 text-blue-100">
                        ¡Obtén un 10% de descuento en tu primera visita y diagnóstico gratuito!
                    </p>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                        <a href="https://wa.link/wo9nty">
                            <button
                                className="bg-white text-blue-900 px-8 py-4 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-50 transition-colors">
                                <Calendar className="w-5 h-5"/>
                                Agendar Visita Técnica
                            </button>
                        </a>
                        <a href="https://wa.link/wo9nty">
                            <button
                                className="bg-green-500 text-white px-8 py-4 rounded-lg flex items-center justify-center gap-2 hover:bg-green-600 transition-colors">
                                <MessageCircle className="w-5 h-5"/>
                                Consulta por WhatsApp
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}